import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

//---helpers
import { CATALOG_PRODUCTS_TYPE, PRODUCTS_ORDER_KEY } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import ProductsBlock from '@/components/products/ProductsBlock/index.vue';
import Pagination from '@/components/@general/Pagination/index.vue';
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';

export default {
  name: 'CatalogType',
  mixins: [dynamicHead],
  components: {
    BreadcrumbsSimple,
    ProductsBlock,
    Pagination
  },
  data() {
    return {
      PRODUCTS_ORDER_KEY,
      catalogType: CATALOG_PRODUCTS_TYPE.BESTSELLERS,
      catalogTypeProducts: [],
      catalogTypeMeta: null,
      isCatalogTypeLoading: false
    };
  },
  created() {
    let { name } = this.$route;
    if (name.indexOf(CATALOG_PRODUCTS_TYPE.NEWEST) > -1) {
      this.catalogType = CATALOG_PRODUCTS_TYPE.NEWEST;
    }
    this._loadContent();
  },
  watch: {
    $route: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this._getProducts();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
      metaData: 'meta/metaData'
    }),
    pageTitle() {
      return this.catalogType === CATALOG_PRODUCTS_TYPE.NEWEST
        ? this.$t('page.catalog.newestTitle')
        : this.$t('page.catalog.bestSellersTitle');
    },
    paginationRootPath() {
      return this.catalogType === CATALOG_PRODUCTS_TYPE.NEWEST ? 'novinki' : 'top-prodazh';
    },
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.catalogTypeProducts;
      }

      const favourites = _.map(this.favourites, 'productId');
      const markAsSelected = (product) => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      return _.map(this.catalogTypeProducts, markAsSelected);
    }
  },
  methods: {
    ...mapActions({
      getCatalogProducts: 'catalog/GET_CATALOG_PRODUCTS',
      getMetaData: 'meta/GET_META_DATA'
    }),
    _getProducts() {
      this.isCatalogTypeLoading = true;
      let { params: routeParams, query } = this.$route;
      let { page } = routeParams;
      let { orderby } = query;

      let sortFilterType = orderby
        ? orderby
        : this.catalogType === CATALOG_PRODUCTS_TYPE.NEWEST
          ? PRODUCTS_ORDER_KEY.NEWEST
          : PRODUCTS_ORDER_KEY.BESTSELLERS;

      return this.getCatalogProducts({
        page: page ? Number(page) : 1,
        sort_filter_type: sortFilterType
      })
        .then((result) => {
          this.catalogTypeProducts = result.data;
          this.catalogTypeMeta = result.meta;
        })
        .finally(() => {
          this.isCatalogTypeLoading = false;
        });
    },
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      promiseCollection.push(this._getProducts());
      promiseCollection.push(
        this.getMetaData(this.catalogType === CATALOG_PRODUCTS_TYPE.NEWEST ? 'novinki' : 'top-prodazh').then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
